import React from 'react';

class Error extends React.Component {
	render() {
		return(
			<h1>Error 404 dommage</h1>
		);
	}
}

export default Error
